@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: "Raleway", sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Raleway", "Roboto",
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.simple_link {
  color: inherit;
  text-decoration: none;
}
.paper__padding {
  padding: 20px;
}
.form__control__custom {
  margin-bottom: 20px;
}

.main__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
  align-items: center;
}
.card__container {
  background-color: #ffffff;
  box-shadow: 0px 2px 8px #848484;
  border-radius: 4px;
  padding: 20px;
}
.icon__container {
  display: flex;
  justify-content: center;
}
.number__container {
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  color: #f36e20;
}
.text__container {
  display: flex;
  justify-content: center;
  font-size: 1em;
}

.donate__loginPage__container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  background-color: #fafafa;
}
.donate__login__paper {
  padding: 20px;
  text-align: center;
  width: 325px;
  max-width: 95%;
  margin: 20vh auto auto;
}
.donate__login__form {
  display: grid;
}
.donate__login__el {
  margin-bottom: 15px !important;
}
.donate__login__form Button {
  margin: 10px 0;
}

.image__preview__container {
  width: 100%;
  display: block;
}
.image__preview {
  width: 100%;
  height: auto;
  max-height: 200px;
  cursor: pointer;
  object-fit: cover;
}
.custom__col2 {
  width: 50%;
  display: inline-block;
}

.collect__month__container {
  position: relative;
  min-height: 200px;
  height: -webkit-max-content;
  height: max-content;
  border-radius: 10px;
  background-size: cover;
  background-color: white;
  padding: 40px;
  background-position: right top;
}

.collect__month__info {
  width: 60%;
}
.collect__month__title {
  color: white;
  font-weight: bold;
  font-size: 24px;
  margin-top: 0px;
}
.collect__month__description {
  color: white;
  font-size: 14px;
  line-height: 1em;
}

.collect__month__btn_container {
  position: absolute;
  bottom: 40px;
  right: 40px;
}
.collect__month__btn {
  background-color: white;
  color: #333;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 50px;
}
.collect__month__btn:hover {
  text-decoration: none;
  color: inherit;
}

.next__event__container {
  position: relative;
}
.next__event__info {
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.next__event__img {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-radius: 10px;
}
.next__event__eltitle {
  margin: 5px 0 20px;
  padding: 0;
  color: white;
  font-weight: bold;
  font-size: 38px;
  line-height: 1em;
}
.next__event__elSlogan {
  margin: 5px 0 20px;
  padding: 0;
  color: white;
  font-size: 28px;
  line-height: 1em;
}
.next__event__btn {
  background-color: white;
  color: #333;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 50px;
}

.next__event__btn:hover {
  text-decoration: none;
  color: inherit;
  opacity: 0.9;
}

.btn__angle__right {
  color: #f36e20;
  font-size: 18px;
  margin-left: 10px;
}

.project__container {
  /* display: grid;
  grid-template-columns: 190px calc(100% - 170px); */
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: 0px 10px 20px 0px rgba(168, 168, 168, 1);
  margin-bottom: 20px !important;
  position: relative;
}

.project__actions__btns {
  position: absolute;
  right: 12px;
  top: 0px;
}

.actualstep__dropShadow {
  width: 40px !important;
  margin-top: -19px !important;
}
.project__image_container {
  text-align: center;
}

.project__image_container img {
  width: 160px;
  height: 160px;
  border-radius: 5px;
  object-fit: cover;
}

/* .project__info_container {
} */
.project__info_container_inner {
  display: flex;
}
.project__info_title_desc {
  width: 50%;
  padding-right: 10px;
}
.project__info_title_desc h3 {
  font-size: 18px;
  font-weight: bold;
}
.project__info__status__badge {
  font-size: 13px;
  padding: 3px 10px;
  background: #ec6122;
  border-radius: 25px;
  color: white;
  white-space: nowrap;
}
.project__ended__bg {
  background-color: #3ecd99 !important;
}
.project__ended__color {
  color: #3ecd99 !important;
}
.project__info_title_desc p {
  font-size: 13px;
}
.project__info_percentage {
  width: 50%;
  padding-left: 10px;
  padding-right: 20px;
}
.project__progress_bar {
  position: relative;
  background-color: #f3f3f3;
  border-radius: 25px;
  height: 10px;
  width: 100%;
}
.project__progress {
  position: absolute;
  background-color: #ec6122;
  border-radius: 25px;
  height: 10px;
}
.project__info__amount__collected,
.project__info__amount__goal {
  margin-bottom: 10px;
  font-size: 15px;
}
.project__info__amount__collected b {
  color: #ec6122;
}
.project__info__amount__collected__percentage {
  /* float: right; */
  font-size: 24px;
  font-weight: bold;
}
.project__info__amount__goal {
  color: #7f7f7f;
  font-weight: bold;
}

/* .project__state_container {
   width: 100%;
  padding-right: 20px; 
} */
.project__status_line__cont {
  height: 50px;
  margin: 25px auto auto;
  display: flex;
}
.project__status_line {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  height: 5px;
  position: relative;
  margin: auto;
  padding: 0;
}
.project__state__icon {
  position: absolute;
  width: 50px;
  line-height: 0.5em;
  text-align: center;
}
.project__state__icon img {
  margin-top: -23px;
  width: 50px;
}

.project__step__title {
  font-size: 10px;
  font-weight: bold;
  line-height: 1em;
}

.project__status_line_ended {
  padding-right: 0;
  margin-top: 20px;
}

.project___status__ended_details_cont {
  font-size: 13px;
  padding: 0;
}
.project___status__ended_details_cont b {
  color: #ec6122;
  font-size: 16px;
}

.imageProjectpreview {
  width: 150px;
  height: auto;
  max-height: 150px;
  cursor: pointer;
  object-fit: cover;
}
.projectimage__preview__container {
  width: 75%;
  display: inline-block;
  cursor: pointer;
}
.image__name__label {
  display: inline-block;
  width: 25%;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.media__lib__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
  grid-gap: 1em;
  gap: 1em;
  justify-content: flex-start;
}
.media__lib__image__container {
  position: relative;
  background: #e9e9e9;
  border-radius: 4px;
}
.media__lib__image__container > div {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  color: white;
  font-size: 13px;
  display: block;
  font-weight: bold;
  background: red;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  line-height: 13px;
  text-align: center;
  padding: 2px;
}
.media__lib__image__container img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.message__card__paper {
  padding: 10px 20px;
  width: 80%;
}
.ticket__card__container {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr 1fr;
  align-items: center;
}

.ticket__card__container .ticket__card__el_center {
  text-align: center;
}

.ticket__card__el_badge {
  color: #ffffff;

  border-radius: 25px;
  padding: 2px 5px;
  font-weight: bold;
  font-size: 13px;
}
.messages__container {
  margin: 50px 0;
}

.message__card__info_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.message__card__user,
.message__card__date {
  color: gray;
  font-weight: bold;
}

.message__card__attachement_container {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: gray;
  color: white;
  border-radius: 4px;
  padding: 5px;
}

.message__card__attachement__download__btn {
  width: 100%;
  position: absolute;
  display: flex;

  margin: auto;
  padding: 2px 0;

  bottom: 0;
  left: 0;
  right: 0;

  text-align: center;

  color: white;
  background-color: rgba(0, 0, 0.7);
  cursor: pointer;

  border-radius: 4px;
  border-radius: 4px;
}
.message__card__attachement__download__btn a {
  color: white;
  text-decoration: none;
}

.message__card__attachement_container:hover
  .message__card__attachement__download__btn {
  top: 0;
}

.message__card__attachement__download__btn span {
  margin: auto;
}

.badge_open {
  background-color: #f36e20;
}

.badge_closed {
  background-color: #37a558;
}

.pagination__container {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;

  align-items: center;
}

