.donate__loginPage__container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  background-color: #fafafa;
}
.donate__login__paper {
  padding: 20px;
  text-align: center;
  width: 325px;
  max-width: 95%;
  margin: 20vh auto auto;
}
.donate__login__form {
  display: grid;
}
.donate__login__el {
  margin-bottom: 15px !important;
}
.donate__login__form Button {
  margin: 10px 0;
}
