.image__preview__container {
  width: 100%;
  display: block;
}
.image__preview {
  width: 100%;
  height: auto;
  max-height: 200px;
  cursor: pointer;
  object-fit: cover;
}
.custom__col2 {
  width: 50%;
  display: inline-block;
}
