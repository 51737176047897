.imageProjectpreview {
  width: 150px;
  height: auto;
  max-height: 150px;
  cursor: pointer;
  object-fit: cover;
}
.projectimage__preview__container {
  width: 75%;
  display: inline-block;
  cursor: pointer;
}
.image__name__label {
  display: inline-block;
  width: 25%;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.media__lib__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
  gap: 1em;
  justify-content: flex-start;
}
.media__lib__image__container {
  position: relative;
  background: #e9e9e9;
  border-radius: 4px;
}
.media__lib__image__container > div {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  color: white;
  font-size: 13px;
  display: block;
  font-weight: bold;
  background: red;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  line-height: 13px;
  text-align: center;
  padding: 2px;
}
.media__lib__image__container img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}
