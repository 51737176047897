.simple_link {
  color: inherit;
  text-decoration: none;
}
.paper__padding {
  padding: 20px;
}
.form__control__custom {
  margin-bottom: 20px;
}
