.main__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  gap: 1em;
  align-items: center;
}
.card__container {
  background-color: #ffffff;
  box-shadow: 0px 2px 8px #848484;
  border-radius: 4px;
  padding: 20px;
}
.icon__container {
  display: flex;
  justify-content: center;
}
.number__container {
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  color: #f36e20;
}
.text__container {
  display: flex;
  justify-content: center;
  font-size: 1em;
}
