.next__event__container {
  position: relative;
}
.next__event__info {
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.next__event__img {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-radius: 10px;
}
.next__event__eltitle {
  margin: 5px 0 20px;
  padding: 0;
  color: white;
  font-weight: bold;
  font-size: 38px;
  line-height: 1em;
}
.next__event__elSlogan {
  margin: 5px 0 20px;
  padding: 0;
  color: white;
  font-size: 28px;
  line-height: 1em;
}
.next__event__btn {
  background-color: white;
  color: #333;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 50px;
}

.next__event__btn:hover {
  text-decoration: none;
  color: inherit;
  opacity: 0.9;
}

.btn__angle__right {
  color: #f36e20;
  font-size: 18px;
  margin-left: 10px;
}
