.project__container {
  /* display: grid;
  grid-template-columns: 190px calc(100% - 170px); */
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 10px;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(168, 168, 168, 1);
  -moz-box-shadow: 0px 10px 20px 0px rgba(168, 168, 168, 1);
  box-shadow: 0px 10px 20px 0px rgba(168, 168, 168, 1);
  margin-bottom: 20px !important;
  position: relative;
}

.project__actions__btns {
  position: absolute;
  right: 12px;
  top: 0px;
}

.actualstep__dropShadow {
  width: 40px !important;
  margin-top: -19px !important;
}
.project__image_container {
  text-align: center;
}

.project__image_container img {
  width: 160px;
  height: 160px;
  border-radius: 5px;
  object-fit: cover;
}

/* .project__info_container {
} */
.project__info_container_inner {
  display: flex;
}
.project__info_title_desc {
  width: 50%;
  padding-right: 10px;
}
.project__info_title_desc h3 {
  font-size: 18px;
  font-weight: bold;
}
.project__info__status__badge {
  font-size: 13px;
  padding: 3px 10px;
  background: #ec6122;
  border-radius: 25px;
  color: white;
  white-space: nowrap;
}
.project__ended__bg {
  background-color: #3ecd99 !important;
}
.project__ended__color {
  color: #3ecd99 !important;
}
.project__info_title_desc p {
  font-size: 13px;
}
.project__info_percentage {
  width: 50%;
  padding-left: 10px;
  padding-right: 20px;
}
.project__progress_bar {
  position: relative;
  background-color: #f3f3f3;
  border-radius: 25px;
  height: 10px;
  width: 100%;
}
.project__progress {
  position: absolute;
  background-color: #ec6122;
  border-radius: 25px;
  height: 10px;
}
.project__info__amount__collected,
.project__info__amount__goal {
  margin-bottom: 10px;
  font-size: 15px;
}
.project__info__amount__collected b {
  color: #ec6122;
}
.project__info__amount__collected__percentage {
  /* float: right; */
  font-size: 24px;
  font-weight: bold;
}
.project__info__amount__goal {
  color: #7f7f7f;
  font-weight: bold;
}

/* .project__state_container {
   width: 100%;
  padding-right: 20px; 
} */
.project__status_line__cont {
  height: 50px;
  margin: 25px auto auto;
  display: flex;
}
.project__status_line {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  height: 5px;
  position: relative;
  margin: auto;
  padding: 0;
}
.project__state__icon {
  position: absolute;
  width: 50px;
  line-height: 0.5em;
  text-align: center;
}
.project__state__icon img {
  margin-top: -23px;
  width: 50px;
}

.project__step__title {
  font-size: 10px;
  font-weight: bold;
  line-height: 1em;
}

.project__status_line_ended {
  padding-right: 0;
  margin-top: 20px;
}

.project___status__ended_details_cont {
  font-size: 13px;
  padding: 0;
}
.project___status__ended_details_cont b {
  color: #ec6122;
  font-size: 16px;
}
