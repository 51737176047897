.collect__month__container {
  position: relative;
  min-height: 200px;
  height: max-content;
  border-radius: 10px;
  background-size: cover;
  background-color: white;
  padding: 40px;
  background-position: right top;
}

.collect__month__info {
  width: 60%;
}
.collect__month__title {
  color: white;
  font-weight: bold;
  font-size: 24px;
  margin-top: 0px;
}
.collect__month__description {
  color: white;
  font-size: 14px;
  line-height: 1em;
}

.collect__month__btn_container {
  position: absolute;
  bottom: 40px;
  right: 40px;
}
.collect__month__btn {
  background-color: white;
  color: #333;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 50px;
}
.collect__month__btn:hover {
  text-decoration: none;
  color: inherit;
}
