.message__card__paper {
  padding: 10px 20px;
  width: 80%;
}
.ticket__card__container {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr 1fr;
  align-items: center;
}

.ticket__card__container .ticket__card__el_center {
  text-align: center;
}

.ticket__card__el_badge {
  color: #ffffff;

  border-radius: 25px;
  padding: 2px 5px;
  font-weight: bold;
  font-size: 13px;
}
.messages__container {
  margin: 50px 0;
}

.message__card__info_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.message__card__user,
.message__card__date {
  color: gray;
  font-weight: bold;
}

.message__card__attachement_container {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: gray;
  color: white;
  border-radius: 4px;
  padding: 5px;
}

.message__card__attachement__download__btn {
  width: 100%;
  position: absolute;
  display: flex;

  margin: auto;
  padding: 2px 0;

  bottom: 0;
  left: 0;
  right: 0;

  text-align: center;

  color: white;
  background-color: rgba(0, 0, 0.7);
  cursor: pointer;

  border-radius: 4px;
  border-radius: 4px;
}
.message__card__attachement__download__btn a {
  color: white;
  text-decoration: none;
}

.message__card__attachement_container:hover
  .message__card__attachement__download__btn {
  top: 0;
}

.message__card__attachement__download__btn span {
  margin: auto;
}

.badge_open {
  background-color: #f36e20;
}

.badge_closed {
  background-color: #37a558;
}
